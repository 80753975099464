import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { IMailingAddress } from "@smartrr/shared/entities/MailingAddress";
import { ILoadPaginatedForOrg, serializePaginatedQueryValues } from "@smartrr/shared/utils/paginatedQuery";

import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export function getCustomers({ queryParams }: ILoadPaginatedForOrg) {
  return typedFrontendVendorApi.getReq("/customer-relationship", {
    query: serializePaginatedQueryValues(queryParams),
  });
}

export const loadCustomers =
  ({
    queryParams,
  }: ILoadPaginatedForOrg): // paginatedQuery: ILoadPaginatedForOrg
  SmartrrThunkAction<"LOADING_CUSTOMERS" | "ERROR_LOADING_CUSTOMERS" | "LOADED_CUSTOMERS"> =>
  dispatch =>
    vendorAsyncDispatch(
      getCustomers({ queryParams }),
      () =>
        dispatch({
          type: "LOADING_CUSTOMERS",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_CUSTOMERS",
          payload: {
            errorMessage: failure.message,
          },
        }),
      customers =>
        dispatch({
          type: "LOADED_CUSTOMERS",
          payload: {
            customers,
          },
        })
    );

export const loadCustomer =
  (
    customerRelationshipId: string
  ): SmartrrThunkAction<"LOADING_CUSTOMER" | "ERROR_LOADING_CUSTOMER" | "LOADED_CUSTOMER"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/customer-relationship/:customerRelationshipId", {
        params: { customerRelationshipId },
      }),
      () =>
        dispatch({
          type: "LOADING_CUSTOMER",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_CUSTOMER",
          payload: {
            errorMessage: failure.message,
          },
        }),
      customer =>
        dispatch({
          type: "LOADED_CUSTOMER",
          payload: {
            customer,
          },
        })
    );

export const changeCustomerBirthday =
  (
    customerRelationshipId: string,
    birthdayDate: ISODateString
  ): SmartrrThunkAction<
    "CHANGE_CUSTOMER_BIRTHDAY" | "ERROR_CHANGING_CUSTOMER_BIRTHDAY" | "CHANGED_CUSTOMER_BIRTHDAY"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/customer-relationship/:customerRelationshipId/birthday", {
        params: { customerRelationshipId },
        reqBody: { birthdayDate },
      }),
      () =>
        dispatch({
          type: "CHANGE_CUSTOMER_BIRTHDAY",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_CHANGING_CUSTOMER_BIRTHDAY",
          payload: {
            errorMessage: failure.message,
          },
        }),
      birthdayDate =>
        dispatch({
          type: "CHANGED_CUSTOMER_BIRTHDAY",
          payload: {
            birthdayDate,
            customerRelationshipId,
          },
        })
    );

export const changeCustomerAmountOfPoints =
  ({
    customerRelationshipId,
    orgPoints,
    reason,
  }: {
    customerRelationshipId: string;
    orgPoints: number;
    reason?: string;
  }): SmartrrThunkAction<
    "CHANGE_CUSTOMER_POINTS" | "ERROR_CHANGING_CUSTOMER_POINTS" | "CHANGED_CUSTOMER_POINTS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/customer-relationship/:customerRelationshipId/orgPoints", {
        params: { customerRelationshipId },
        reqBody: {
          points: orgPoints,
          reason,
        },
      }),
      () =>
        dispatch({
          type: "CHANGE_CUSTOMER_POINTS",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_CHANGING_CUSTOMER_POINTS",
          payload: {
            errorMessage: failure.message,
          },
        }),
      orgPoints =>
        dispatch({
          type: "CHANGED_CUSTOMER_POINTS",
          payload: {
            orgPoints,
            customerRelationshipId,
          },
        })
    );

export const updateCustomerAddress =
  (
    updatedMailingAddress: IMailingAddress,
    customerRelationshipId: string
  ): SmartrrThunkAction<
    "UPDATING_CUSTOMER_ADDRESS" | "ERROR_UPDATING_CUSTOMER_ADDRESS" | "UPDATED_CUSTOMER_ADDRESS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/customer-relationship/:customerRelationshipId/default-address/:addressId", {
        params: {
          addressId: updatedMailingAddress.id,
          customerRelationshipId,
        },
        reqBody: {
          updatedMailingAddress,
        },
      }),
      () =>
        dispatch({
          type: "UPDATING_CUSTOMER_ADDRESS",
          payload: undefined,
        }),
      error =>
        dispatch({
          type: "ERROR_UPDATING_CUSTOMER_ADDRESS",
          payload: {
            errorMessage: error.message,
            address: null,
          },
        }),
      ({ defaultAddress }) =>
        dispatch({
          type: "UPDATED_CUSTOMER_ADDRESS",
          payload: {
            defaultAddress,
            customerRelationshipId,
          },
        })
    );
